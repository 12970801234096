import * as React from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

const BoatHeaderSection = ({ boatImage }: { boatImage: IGatsbyImageData }) => {

    return (
        <>
            <div className="container-fluid p-0 position-relative overflow-hidden d-block d-md-none">
                <div
                    className="container-fluid h-100 p-0"
                >
                    <GatsbyImage
                        image={boatImage}
                        alt=""
                    />
                </div>
            </div>
            {/* large screen max height: */}
            <div className="container-fluid p-0 d-none d-md-block bg-white">
                <div
                    className="col-lg-6 col-md-8 offset-0 offset-md-2 offset-lg-3"
                >
                    <GatsbyImage
                        image={boatImage}
                        alt=""
                    />
                </div>
            </div>
        </>
    )
}

export default BoatHeaderSection