// i18next-extract-mark-ns-start boat_page_info
import { faLocationPin, faMarker, faPeopleGroup, faShip } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import PageSection from "../page-section"

const BoatInfoSection = ({ boat }: { boat: { name: string, type: string, capacity: number, pinpointName: string, pinpointUrl: string } }) => {

    const { t } = useTranslation();
    
    return (
        <PageSection>
            <div className="container p-0">
                <div className="row">
                    <div className="col-sm-6 col-12">
                        <h1><FontAwesomeIcon icon={faShip} className="pe-3" />{boat.name}</h1>
                        <h4 className="lead text-white">{t(boat.type)  /* i18next-extract-disable-line */}</h4>
                    </div>
                    <div className="col-sm-6 col-12 mt-3">
                        <p><FontAwesomeIcon icon={faPeopleGroup} className="pe-2" /><Trans>Pojemność</Trans>: {boat.capacity} <Trans>os.</Trans></p>
                        <p><FontAwesomeIcon icon={faLocationPin} className="pe-2" /><Trans>Rejsy z</Trans>: <a href={boat.pinpointUrl} target="_blank">{boat.pinpointName}</a></p>
                    </div>
                </div>
            </div>

            <div className="py-2"></div>

        </PageSection>
    )
}

export default BoatInfoSection