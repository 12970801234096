import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BoatHeaderSection from "../../components/boat/BoatHeaderSection"
import BoatInfoSection from "../../components/boat/BoatInfoSection"
import { getImage } from "gatsby-plugin-image"
import slugify from 'slugify';

const BoatPage = (props) => {

    const boat = props.data.boat;
    const image = getImage(props.data.boat.image);

    return (
        <Layout>
            <Seo title={boat.name} />
            <BoatHeaderSection boatImage={image} />
            <BoatInfoSection boat={boat} />
        </Layout >
    )
}


export default BoatPage

export const query = graphql`
            query($id: String,$language: String!) {
            boat(id: {eq: $id }) {
                name
                type
                capacity
                pinpointName
                pinpointUrl
                image {
                    childImageSharp {
                        gatsbyImageData(
                            layout: FULL_WIDTH
                            formats: [AUTO, WEBP, AVIF]
                            placeholder: BLURRED
                        )
                    }
                }
            }
            locales: allLocale(filter: {ns:{in: ["navigation","footbar","boat_types","boat_page_info"]}, language: {eq: $language}}) {
                edges {
                node {
                ns
            data
            language
        }
        }
    }
  }
            `